import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n  ",
        "\n\n  &.box-trade {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    padding: 7.5px 16px;\n    gap: 8px;\n\n    background: #ff4d4f;\n    border-radius: 8px;\n\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 150%;\n\n    color: #ffffff;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { m as motion } from "framer-motion";
import styled from "styled-components";
import { background, border, layout, position, space, color } from "styled-system";
export var MotionBox = styled(motion.div).withConfig({
    componentId: "sc-855bd3c7-0"
})(_templateObject(), background, border, layout, position, space);
var Box = styled.div.withConfig({
    componentId: "sc-855bd3c7-1"
})(_templateObject1(), background, border, layout, position, space, color);
export default Box;
