import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address | null, chainId?: number): string => {
  if(address)
    return address[chainId] ? address[chainId] : address[ChainId.BSC]
  return ''
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(null, chainId)
}
export const getMasterChefV1Address = () => {
  return getAddress(null)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getLotteryV2Address = () => {
  return getAddress(null)
}
export const getPancakeProfileAddress = () => {
  return getAddress(null)
}
export const getPancakeBunniesAddress = () => {
  return getAddress(null)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(null)
}
export const getPredictionsV1Address = () => {
  return getAddress(null)
}
export const getClaimRefundAddress = () => {
  return getAddress(null)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(null)
}
export const getBunnySpecialAddress = () => {
  return getAddress(null)
}
export const getTradingCompetitionAddressEaster = () => {
  return getAddress(null)
}
export const getTradingCompetitionAddressFanToken = () => {
  return getAddress(null)
}

export const getTradingCompetitionAddressMobox = () => {
  return getAddress(null)
}

export const getTradingCompetitionAddressMoD = () => {
  return getAddress(null)
}

export const getEasterNftAddress = () => {
  return getAddress(null)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getCakeVaultAddress = () => {
  return getAddress(null)
}

export const getCakeFlexibleSideVaultAddress = () => {
  return getAddress(null)
}

export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(null)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(null)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(null)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(null)
}
export const getFarmAuctionAddress = () => {
  return getAddress(null)
}
export const getAnniversaryAchievement = () => {
  return getAddress(null)
}

export const getNftMarketAddress = () => {
  return getAddress(null)
}
export const getNftSaleAddress = () => {
  return getAddress(null)
}
export const getPancakeSquadAddress = () => {
  return getAddress(null)
}
export const getPotteryDrawAddress = () => {
  return getAddress(null)
}

export const getZapAddress = () => {
  return getAddress(null)
}
export const getICakeAddress = () => {
  return getAddress(null)
}

export const getBCakeFarmBoosterAddress = () => {
  return getAddress(null)
}

export const getBCakeFarmBoosterProxyFactoryAddress = () => {
  return getAddress(null)
}
