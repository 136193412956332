import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        "\n\n  &.box-already-login {\n    margin-left: 8px;\n\n    padding: 10px 16px;\n    gap: 8px;\n\n    height: 36px;\n\n    border: 1px solid #ffffff;\n    border-radius: 8px;\n\n    div {\n      background: none;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-176ada8e-0"
})(_templateObject(), flexbox);
export default Flex;
