import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  AnalyticsIcon,
  PresaleIcon,
  BridgesIcon,
  ScreenerIcon,
  AboutIcon,
  MysteryBoxIcon,
  YourNFTIcon,
  StakeIcon,
  VoteMenuIcon,
  LiquidityIcon,
  PioneerIcon,
  PredictionIcon,
  ReferralsIcon,
  LeaderboardIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Dashboard'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://www.opx.finance/#/dashboard',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Earn'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://www.opx.finance/#/earn',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Liquidity Provider Earn'),
          href: 'https://app.opx.finance/earn',
          icon: LiquidityIcon,
          describe: 'Provide liquidity to earn $OLP, stake $OLP to earn rewards in both $WETH and $OPX.',
        },
        {
          label: t('Pioneer Program'),
          href: 'https://app.opx.finance/pioneer',
          icon: PioneerIcon,
          describe: 'Stake your Pioneer NFT to earn Profit Sharing in $WETH.',
        },
      ],
    },
    {
      label: t('Buy'),
      href: 'https://www.opx.finance/#/buy',
      showItemsOnMobile: true,
      items: [],
    },
    {
      label: t('Governance'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: 'https://gov.opx.finance/lock',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Stake'),
          href: 'https://www.opx.finance/#/stake',
          icon: StakeIcon,
          describe: 'Lock $OPX with OPX NFT to get veOPX. Stake veOPX to gain profit share in $WETH.',
        },
        {
          label: t('Vote'),
          href: 'https://www.opx.finance/#/vote',
          icon: VoteMenuIcon,
          describe: 'Use your veOPX to decide the distribution of rewards for the current epoch.',
        },
      ],
    },
    {
      label: t('Analytics'),
      href: '',
      showItemsOnMobile: true,
      items: [
        {
          icon: AnalyticsIcon,
          label: t('Stats'),
          href: 'https://stats.opx.finance/',
          describe: 'Track, measure the project performance, and more.',
        },
        {
          icon: LeaderboardIcon,
          label: t('Leaderboard'),
          href: 'https://leaderboard.opx.finance/',
          describe: 'Display the ranking of traders on OPX.',
        },
      ],
    },
    {
      label: t('NFTs'),
      href: '',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      items: [
        {
          label: t('Mystery Box'),
          href: 'https://www.opx.finance/#/mystery-box',
          icon: MysteryBoxIcon,
          describe: 'Random but unknown OPX NFT with different rarity levels.',
        },
        {
          label: t('Your NFT'),
          href: 'https://www.opx.finance/#/mystery-box/your-nft',
          icon: YourNFTIcon,
          describe: 'Your available OPX NFT.',
        },
      ],
    },
    {
      label: t('Games'),
      href: '',
      showItemsOnMobile: true,
      items: [
        {
          icon: PredictionIcon,
          label: t('Prediction'),
          href: 'https://prediction.opx.finance/',
          describe: 'Guess correctly whether the ETH price will rise or fall to win!',
        },
      ],
      isActive: true,
    },
    // {
    //   label: t('Referrals'),
    //   icon: SwapIcon,
    //   fillIcon: SwapFillIcon,
    //   href: 'https://www.opx.finance/#/referrals',
    //   showItemsOnMobile: false,
    //   items: [],
    // },
    {
      label: t('More'),
      href: '',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      items: [
        {
          icon: ReferralsIcon,
          label: t('Referrals'),
          href: 'https://www.opx.finance/#/referrals/',
          describe: 'Get fee discounts and earn rebates by inviting your friends.',
        },
        // {
        //   icon: AnalyticsIcon,
        //   label: t('Analytics'),
        //   href: 'https://stats.opx.finance/',
        //   describe: "Track, measure the project performance, and more."
        // },
        {
          icon: PresaleIcon,
          label: t('Presale'),
          href: 'https://www.opx.finance/#/presale',
          describe: 'You can claim your $OPX after the Presale ends.',
        },
        {
          icon: BridgesIcon,
          label: t('Bridges'),
          href: 'https://www.optimism.io/apps/bridges',
          describe: 'The gateway to connect across multichain.',
        },
        {
          icon: ScreenerIcon,
          label: t('OPX Dex Screener'),
          href: 'https://dexscreener.com/optimism/0xc62d5ff62276eeda3c2d24f6b7e384355ec52a5e',
          describe: 'Realtime price chart and trading history on OPX.',
        },
        {
          icon: AboutIcon,
          label: t('About'),
          href: 'https://docs.opx.finance/',
          describe: 'OPX Documentations',
        },
      ],
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
