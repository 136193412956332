import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, RedditIcon, DiscordIcon, YoutubeIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://medium.com/pancakeswap"
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram"
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake"
            },
            {
                label: "—"
            },
            {
                label: "Online Store",
                href: "https://pancakeswap.creator-spring.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap"
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty"
            },
            {
                label: "Audits",
                href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited"
            },
            {
                label: "Careers",
                href: "https://docs.pancakeswap.finance/hiring/become-a-chef"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/opxfinance"
    },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/mNdHE52zZp"
    },
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/opxfinance"
    },
    {
        label: "Reddit",
        icon: RedditIcon,
        href: "https://www.reddit.com/r/DarkCrypto_Official/"
    },
    {
        label: "Youtube",
        icon: YoutubeIcon,
        href: "https://www.youtube.com/channel/UCC8-Fd99fpk9OYqiZtXc3Rw"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
export var FooterList = [
    {
        title: "DEFI",
        children: [
            {
                name: "DarkAuto",
                to: "https://www.darkauto.finance/",
                isExternal: true
            },
            {
                name: "DarkCrypto",
                to: "https://www.darkcrypto.finance/",
                isExternal: true
            },
            {
                name: "DarkSwap",
                to: "https://www.darkness.finance/#/trade          ",
                isExternal: true
            },
            {
                name: "DarkVerse",
                to: " https://www.darkcrypto.finance/darkverse          ",
                isExternal: true
            },
            {
                name: "DarkNess",
                to: "https://www.darkness.finance/#/",
                isExternal: true
            }, 
        ]
    },
    {
        title: "Play to earn",
        children: [
            {
                name: "DarkRoll",
                to: "https://darkroll.darkcrypto.finance/",
                isExternal: true
            },
            {
                name: "DarkSport",
                to: "https://sport.darkverse.app/",
                isExternal: true
            },
            {
                name: "DarkPortal",
                to: "https://vegas.darkcrypto.finance/",
                isExternal: true
            }, 
        ]
    },
    {
        title: "DOCUMENTS",
        children: [
            {
                name: "GMX Fork",
                to: "https://github.com/gmx-io/gmx-contracts/tree/master/audits",
                isExternal: true
            },
            {
                name: "Medium",
                to: "https://medium.com/@opxfi",
                isExternal: true
            },
            {
                name: "Github",
                to: "https://github.com/opxfinance",
                isExternal: true
            }, 
        ]
    },
    {
        title: "Contact us",
        children: [
            {
                name: "Email",
                to: "mailto:contact@darkcrypto.finance",
                isExternal: true
            },
            {
                name: "Telegram",
                to: "https://t.me/opxfinance",
                isExternal: true
            },
            {
                name: "Discord",
                to: "https://discord.gg/mNdHE52zZp",
                isExternal: true
            }, 
        ]
    }, 
];
